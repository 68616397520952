@import '../../styled/_variables';
@import '../../styled/_mixins';
header {
    background: #000000;
    color: $white-color;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center
}
.nav {
  @extend %flex-between;
}
.logo {
    flex: 20%;

    img {
        max-width: 60px;
        object-fit: contain;
    }
    a {
        display: flex;
        background: url(../../../src/images/logo1.png);
        background-size: cover;
        height: 60px;
        width: 60px;
    }
}

.navigation {
    display: flex;
    ul {
        display: flex;
        a {
            padding: 0 26px;
            color: $white-color;
            font-weight: 600;
            transition: .4s color;
            &:hover {
                color: $blue-color;
            }
            &.active {
                color: $blue-color;
            }
        }
        
    }
    @include md {
        display: none;
      }
}
.navigate {
    display: flex;
    flex: 20%;
    justify-content: flex-end;
    &__block {
        display: flex;
        align-items: center;
        a {
            @include xs {
                padding: 10px;
            }
        }
    }
    &__img {
        max-width: 30px;
        border-radius: 50%;
        @include xs {
            margin-right: 10px;
        }
    }
    strong {
        padding: 0px 8px;
        @include xs {
            display: none;
        }
    }
}
.burger {
    position: absolute;
    background: #000;
    max-width: 600px;
    width: 100%;
    height: 100vh;
    padding-top: 90px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: center;
    transform: translateX(100%);
    transition: .6s ease-in;
    opacity: 0;
    .burger_button {
        position: absolute;
        top: 30px;
        left: 30px;
    }
    &_active {
        opacity: 1;
        transform: translateX(0%);
    }
    &_link {
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
        transition: .3s;
        &:hover {
            color: $blue-color;
        }
    }
   &_button {
        height: 26px;
        width: 32px;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 30px;
        
        @include md {
            display: flex;
        }
        .line {
            display: block;
            height: 4px;
            width: 100%;
            border-radius: 10px;
            background: $blue-color;
            &:nth-child(1) {
                transform-origin: 0% 0%;
                transition: transform 0.4s ease-in-out;
            }
            &:nth-child(2) {
                transition: transform 0.2s ease-in-out;
            }
            &:nth-child(3) {
                transform-origin: 0% 100%;
                transition: transform 0.4s ease-in-out;
            }
        }
        &_active {
            .line {
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: scaleY(0);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
        @include xs {
           margin-left: 20px;
        }
   }
}
.toggler_theme {
    cursor: pointer;
    background: transparent;
    border: 2px solid $blue-color;
    position: absolute;
    padding: 2px;
    width: 50px;
    height: 25px;
    border-radius: 20px;
    display: flex;
    left: 20px;
    align-items: center;
    @include md {
        left: 100px;
    }
    & > div {
        height: 17px;
        width: 17px;
        border-radius: 50%;
        position: absolute;
        transition: .4s all;
        
        &.day {
            background: url(../../images/sun.png) ;
            background-size: cover;
            transform: translate(0px, 0px) rotate(180deg);
        }
        &.night {
            background: url(../../images/moon.png) ;
            background-size: cover;
            transform: translate(24px, 0px) rotate(-0deg);
        }
    }
}