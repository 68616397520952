@import '../../styled/_variables';
@import '../../styled/_mixins';

.main_page {
    text-align: center;
    @include xs {
        padding-top: 10px;
    }
}
.heading {
    font-size: 120px;
    line-height: 110px;
    letter-spacing: 2px;
    margin:30px auto 70px ;
    // font-weight: 400;
    // max-width: 900px;
    max-width: 950px;
    font-weight: 900;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #08035c 0%, #0099ff 55%, #08035c 100%);
    background-size: 200% 100%;
    transition: .9s all;
    cursor: pointer;
    &:hover {
        background-position: 100% ;
    }
    @include sm {
        font-weight: 400;
    }
   
    &__description {
        font-size: 26px;
        line-height: 36px;
        color: $gray-color;
        max-width: 510px;
        margin: 0 auto 30px;
      
        @include xs {
            font-size: 20px;
            line-height: 24px;
        }
    }
    @include md {
        font-size: 80px;
        line-height: 90px;
        max-width: 700px;
        margin-bottom: 40px;
    }
    @include xs {
        font-size: 50px;
        line-height: 55px;
        margin-bottom: 20px;
        letter-spacing: -4px;
        max-width: 300px;
    }
}
.companys {
    margin: 10px 0px 90px ;
}
.datalist { 
    position: relative;
    width: fit-content;
    margin: 0 auto 50px;
   
    .input_text {
        border-radius: 8px;
        transition: .4s all;
        &::placeholder {
            font-weight: 700;
            color: $blue-color;
        }
        &:focus {
            border-radius: 8px 8px 0px 0px;
            border-bottom: none;
        }
    }
}
.ctn {
    transform: translateX(-100%);
    position: absolute;
    opacity: 0;
    display: none;
    transition: .5s all;
    pointer-events: none;
   
    & > a {
        display: block;
    }
}
.work {
    position: absolute;
    width: 100%;
    z-index: 10;
    text-align: start;
    background: $bg-color ;
    transform: translateX(0%);
    opacity: 1;
    transition: .5s all;
    border: 2px solid $blue-color;
    border-top: none;
    border-radius: 0px 0px 8px 8px ;
    max-height: 150px;
    overflow-y: scroll;
    & > a {
        display: block;
        padding: 12px 10px;
        &:hover {
            background: $blue-color;
            color: $white-color;
        }
    }
}
