@import './variables.scss';
@import './mixins.scss';
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
body {
    background: $bg-color;
}
a {
    text-decoration: none;
    color: inherit;
}
a,li,button {
    cursor: pointer;
}
img {
    max-width: 100%;
}
ul {
    list-style-type: none;
}
section {
    margin-top: 60px;
    padding-top: 10px;
    min-height: calc(100vh - 0px);
    @include md {
        min-height: calc(100vh - 70px);
    }
}
input, textarea {
    &:focus-visible {
        outline: none;
        outline-offset: none;
    }
}
.container {
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;
    padding: 0 2.5rem;
    @include md {
        padding: 0 1rem;
    }
    @include xs {
        padding: 0 5px;
    }
}
.dark {
    color: white;
    div {
        p,h1,h2,h3,h4,strong,button {
            color: white !important;
        }
    }
    section {
        background: linear-gradient(180deg, #000000 0%, #35393f 40%, #35393f 70%, #000000 100%);
    }
    input, textarea {
        color: inherit ;
        background: inherit;
    }
}
