@import '../../styled/_variables';
@import '../../styled/_mixins';
.footer {
    background: #000000;
    padding: 25px 0px;
    color: $white-color;
    &__nav {
        @extend %flex-between;
        margin-bottom: 20px;
    }
    &__body {
        display: flex;
        @extend %flex-between;
        flex-wrap: wrap;
        align-items: flex-start;
        .ul_list {
            display: flex;
        }
        .logo {
            font-size: 40px;
            margin-right: 30px;
            img {
                max-width: 100px;
                object-fit: contain;
            }
        }
        .contacts {
            max-width: 300px;
            p {
                margin-bottom: 10px;
            }
        }
    }
    &__check {
        @extend %flex-between;
        align-items: flex-start;
        @include xs {
            flex-wrap: wrap;
            margin-bottom: 15px;
            flex-direction: column;
        }
        ul {
            min-width: 150px;
            @include xs {
                min-width: 100px;
            }
        }
        li {
            font-size: 16px;
            margin-bottom: 7px;
        }
        h5 {
            font-size: 20px;
            margin-bottom: 10px;
            transition: .4s color;
            cursor: pointer;
            &:hover {
                color: $blue-color;
                transition: .4s;
            }
        }
    }
}