@import '../../styled/_variables';
@import '../../styled/_mixins';
.news {
    &__list {
        background: transparent;
    }
    &__block {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 120px;
        &:nth-child(2n) {
            flex-direction: row-reverse;
        }
        @include md {
            justify-content: space-between;
            padding-bottom: 50px;
        }
        @include sm {
            justify-content: center;
        }
    }
    &__image {
        display: flex;
        flex-direction: column;
        align-items: center;
       
        img {
            max-width: 600px;
            border-radius: $border-r-medium;
            @include md {
                max-width: 500px;
                width: 100%;
            }
            @include xs {
                width: 100%;
            }
        }
        p {
            color: $gray-color;
            font-size: $fz-description;
            margin-top: 10px;
        }
    }
    &__description {
        max-width: 480px;
        margin: 0 auto;
        @extend %flex-between;
        flex-direction: column;
        align-items: flex-start;
        color: $some-black-color;
        @include md {
            margin: 0;
        }
       
        h2 {
            font-size: $fz-heading;
            line-height: 64px;
            letter-spacing: 1.5px;
            @include md {
                font-size: 48px;
                line-height: 50px;
                margin-bottom: 10px;
            }
            @include xs {
                font-size: 35px;
                line-height: 35px;
            }
        }
        p {
            font-size: $fz-primary;
            @include md {
                font-size: 20px;
                margin-bottom: 15px;
            }
            @include xs {
                font-size: 18px;
                line-height: 18px;
            }
        }
        @include md {
            max-width: 400px;
        }
        @include sm {
            max-width: 500px ;
            margin-top: 30px;
        }
    }
}
.button_primary {
    color: $black-color;
}