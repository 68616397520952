.rules {
    padding: 30px 0px;
    h1 {
        margin-bottom: 25px;
    }
    h2,h3 {
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 8px;
    }

    li {
        list-style-type:disc ;
        margin-left: 30px;
        margin-bottom: 8px;
    }
}